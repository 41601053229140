import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ThemeContext } from 'styled-components';

import {
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
  Thumbnail
} from '../../../../../sharedComponents';
import { Line } from '../../../../styles';

import { TextWrapper } from './index.style';
import { VideoLinkPosition } from '../../../../types';

export const LeftPosition = (props) => {
  const {
    thumbnail,
    linkPosition,
    poster,
    title,
    titleSize,
    text,
    link,
    toggleModal,
    standardVideoWidth,
    videoPlay,
    playerComponent,
  } = props;
  const themeConfig = useContext(ThemeContext);
  const { dStyles, videoPosition } = themeConfig.module;
  const rowClassNames = classNames('row', {
    reverse: themeConfig.globalVariables.isRTL,
  });
  const inline = linkPosition === VideoLinkPosition.INLINE;
  const displayStyles = dStyles[videoPosition];
  const {
    elements: {
      imageTag: { settings: { sm = 6, md = 7, pos = 'first-xs' } = {} } = {},
    },
  } = displayStyles;

  return (
    <div className="container" data-testid="video-left">
      <div className={rowClassNames}>
        <div className={`col-xs-12 col-sm-${sm} col-md-${md} ${pos}`}>
          {videoPlay ? (
            playerComponent
          ) : (
            <Thumbnail
              path={thumbnail}
              imgix={!!poster}
              onClick={toggleModal}
              testId="video-thumbnail"
            />
          )}
        </div>
        <div
          className={`col-xs-12 col-sm-${12 - sm} col-md-${12 - md} text-left`}
        >
          <TextWrapper>
            {title && (
              <StyledHeading
                content={title}
                as={titleSize}
                testId="video-title"
              />
            )}
            {standardVideoWidth && <Line data-testid="video-line" />}
            <StyledParagraph content={text} testId="video-text" />
            {inline && link?.type && (
              <StyledCallToAction ctaConfig={link} testId="video-cta-inline" />
            )}
          </TextWrapper>
        </div>
      </div>
      {!inline && link?.shown && (
        <div className="row">
          <div className="col-xs-12">
            <StyledCallToAction ctaConfig={link} testId="video-cta" />
          </div>
        </div>
      )}
    </div>
  );
};

LeftPosition.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  poster: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    shown: PropTypes.bool,
  }),
  toggleModal: PropTypes.func.isRequired,
  standardVideoWidth: PropTypes.bool.isRequired,
  videoPlay: PropTypes.bool.isRequired,
  playerComponent: PropTypes.node,
  linkPosition: PropTypes.string,
};
