import React from 'react';
import { VideoModal, VideoPlayer} from '../../../sharedComponents';
import {
  TopPosition,
  BottomPosition,
  LeftPosition,
  RightPosition,
} from './displayStyles';
import { Wrapper } from './index.style';
import {
  VideoLinkPosition,
  VideoModulePositions,
  VideoModuleType,
} from '../../types';

export interface VideoModuleProps
  extends Pick<
    VideoModuleType,
    | 'videoId'
    | 'videoPosition'
    | 'videoSource'
    | 'title'
    | 'titleSize'
    | 'text'
    | 'link'
    | 'streamlyUrl'
  > {}

export interface StandardDisplayProps extends VideoModuleProps {
  thumbnail: string;
  modalOpened: boolean;
  standardVideoWidth: boolean;
  videoPlay: boolean;
  toggleModal: () => void;
  poster?: string;
  linkPosition: VideoLinkPosition;
}

export const StandardDisplay = (props: StandardDisplayProps) => {
  const {
    videoId,
    videoSource,
    videoPosition,
    poster,
    title,
    titleSize,
    text,
    link,
    thumbnail,
    modalOpened,
    toggleModal,
    streamlyUrl,
    standardVideoWidth,
    videoPlay,
    linkPosition,
  } = props;

  const PlayerComponent = (
    <VideoPlayer
      source={videoSource}
      id={videoId}
      title={title}
      streamlyUrl={streamlyUrl}
    />
  );

  return (
    <Wrapper>
      {videoPosition === VideoModulePositions.TOP && (
        <TopPosition
          thumbnail={thumbnail}
          poster={poster}
          title={title}
          titleSize={titleSize}
          text={text}
          link={link}
          toggleModal={toggleModal}
          standardVideoWidth={standardVideoWidth}
          videoPlay={videoPlay}
          playerComponent={PlayerComponent}
        />
      )}
      {videoPosition === VideoModulePositions.BOTTOM && (
        <BottomPosition
          thumbnail={thumbnail}
          poster={poster}
          title={title}
          titleSize={titleSize}
          text={text}
          link={link}
          toggleModal={toggleModal}
          standardVideoWidth={standardVideoWidth}
          videoPlay={videoPlay}
          playerComponent={PlayerComponent}
        />
      )}
      {videoPosition === VideoModulePositions.LEFT && (
        <LeftPosition
          thumbnail={thumbnail}
          poster={poster}
          title={title}
          titleSize={titleSize}
          text={text}
          link={link}
          toggleModal={toggleModal}
          standardVideoWidth={standardVideoWidth}
          videoPlay={videoPlay}
          playerComponent={PlayerComponent}
          linkPosition={linkPosition}
        />
      )}
      {videoPosition === VideoModulePositions.RIGHT && (
        <RightPosition
          thumbnail={thumbnail}
          poster={poster}
          title={title}
          titleSize={titleSize}
          text={text}
          link={link}
          toggleModal={toggleModal}
          standardVideoWidth={standardVideoWidth}
          videoPlay={videoPlay}
          playerComponent={PlayerComponent}
          linkPosition={linkPosition}
        />
      )}
      {modalOpened && (
        <VideoModal onClose={toggleModal}>
          <VideoPlayer
            source={videoSource}
            id={videoId}
            title={title}
            streamlyUrl={streamlyUrl}
          />
        </VideoModal>
      )}
    </Wrapper>
  );
};
